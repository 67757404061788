<template>
  <div class="NavFilteredMenu">
    <p class="NavFilteredMenu__title">{{ $t("all.workspaceGroups") }}</p>
    <d-nav-workspace-group
      v-for="workspaceGroups in displayWorkspaceGroups"
      :workspace-group="workspaceGroups"
      :key="workspaceGroups.id"
      ref="workspaceGroupsRef"
    />
    <div v-if="displayWorkspaceGroups.length === 0" class="NavFilteredMenu__noResult">
      {{ $t("sidemenu.noWorkspaceGroups") }}
    </div>

    <p class="NavFilteredMenu__title">{{ $t("all.workspaces") }}</p>
    <nav-workspace-item
      v-for="(workspace, idx) in displayWorkspaceList"
      :key="`${workspace.id}-${idx}`"
      :workspace="workspace"
      :workspaceGroup="workspace.workspaceGroupName"
      showWorkspaceGroupName
    />
    <div v-if="displayWorkspaceList.length === 0" class="NavFilteredMenu__noResult">
      {{ $t("sidemenu.noWorkspaces") }}
    </div>
  </div>
</template>

<script>
import { sortBy } from "lodash-es";
import { mapGetters } from "vuex";
import NavWorkspaceItem from "./NavWorkspaceItem.vue";
import DNavWorkspaceGroup from "./DNavWorkspaceGroup.vue";

export default {
  name: "NavFilteredMenu",
  components: {
    NavWorkspaceItem,
    DNavWorkspaceGroup,
  },
  props: {
    keyword: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fullWorkspaceList: [],
    };
  },
  computed: {
    ...mapGetters("workspaceGroups", ["getter_can_view_workspace_groups"]),
    displayWorkspaceList() {
      const sortedWorkspaceList = sortBy(this.getter_can_view_workspace_groups, ["priority", "name"])
        .map((workspaceGroup) =>
          sortBy(workspaceGroup.workspaces, ["name"]).map((workspace) => ({
            ...workspace,
            workspaceGroupName: workspaceGroup.name,
            workspaceGroupId: workspaceGroup.id,
          }))
        )
        .flat();

      return sortedWorkspaceList.filter((workspace) =>
        workspace.name.toLowerCase().includes(this.keyword.toLowerCase())
      );
    },
    displayWorkspaceGroups() {
      const filteredWorkspaceGroups = this.getter_can_view_workspace_groups.filter((workspaceGroup) =>
        workspaceGroup.name.toLowerCase().includes(this.keyword.toLowerCase())
      );

      const sortedWorkspaceGroups = sortBy(filteredWorkspaceGroups, ["priority", "name"])
        .map((workspaceGroup) =>
          sortBy(workspaceGroup.workspaces, ["name"]).map((workspace) => ({
            ...workspace,
            workspaceGroupName: workspaceGroup.name,
            workspaceGroupId: workspaceGroup.id,
          }))
        )
        .flat();

      return Object.entries(
        Object.groupBy(sortedWorkspaceGroups, ({ workspaceGroupId }) => {
          return workspaceGroupId;
        })
      ).map(([workspaceGroupId, workspaces]) => {
        return {
          name: workspaces[0].workspaceGroupName,
          id: workspaceGroupId,
          workspaces,
        };
      });
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.NavFilteredMenu {
  &__title {
    color: var(--dGrey6-color);
    text-align: start;
    font-size: var(--spacing-04);
    padding: var(--spacing-04) var(--spacing-06) var(--spacing-02) var(--spacing-06);
    margin-bottom: 0;
  }

  &__noResult {
    color: var(--dGrey6-color);
    margin: 24px;
  }
}
</style>
