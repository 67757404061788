<template>
  <nav-collapse
    ref="allWorkspacesRef"
    class="NavAllWorkspaceMenu"
    :title="$t('sidemenu.allWorkspaces')"
    :isExpanded="isExpanded"
    @toggleExpand="handleToggleExpand"
  >
    <d-nav-workspace-group
      v-for="workspaceGroups in displayWorkspaceList"
      :workspace-group="workspaceGroups"
      :key="workspaceGroups.id"
      ref="workspaceGroupsRef"
    />
  </nav-collapse>
</template>

<script>
import { sortBy } from "lodash-es";
import { mapActions, mapGetters, mapState } from "vuex";

import eventbus from "@/main";
import { EVENT } from "@/constants/globalEvents";
import { loggingError } from "@/server/error-log-server";

import NavCollapse from "./NavCollapse.vue";
import NavWorkspaceItem from "./NavWorkspaceItem.vue";
import DNavWorkspaceGroup from "./DNavWorkspaceGroup.vue";
import { playerRoutes } from "@/constants/routers";
import { WORKSPACE_TYPE } from "@/constants/sidemenu";

export default {
  name: "NavAllWorkspaceMenu",
  components: {
    NavCollapse,
    NavWorkspaceItem,
    DNavWorkspaceGroup,
  },
  props: {
    userRole: {
      type: String, // USER_ROLE
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fullWorkspaceList: [],
      playerRoutes,
    };
  },
  computed: {
    ...mapState("workspaceGroups", ["canEditWorkspaceGroups"]),
    ...mapGetters("workspaceGroups", ["getter_can_view_workspace_groups"]),
    displayWorkspaceList() {
      return Object.entries(
        Object.groupBy(this.fullWorkspaceList, ({ workspaceGroupId }) => {
          return workspaceGroupId;
        })
      ).map(([workspaceGroupId, workspaces]) => {
        return {
          name: workspaces[0].workspaceGroupName,
          id: workspaceGroupId,
          workspaces,
        };
      });
    },
  },
  methods: {
    ...mapActions("workspaceGroups", ["getWorkspaceGroupList"]),

    async fetchAllWorkspaces() {
      try {
        await this.getWorkspaceGroupList({ workspacesType: "view" });
        this.fullWorkspaceList = sortBy(this.getter_can_view_workspace_groups, ["priority", "name"])
          .map((workspaceGroup) =>
            sortBy(workspaceGroup.workspaces, ["name"]).map((workspace) => ({
              ...workspace,
              workspaceGroupName: workspaceGroup.name,
              workspaceGroupId: workspaceGroup.id,
            }))
          )
          .flat();
      } catch (error) {
        console.error({ error });
        loggingError(error);
        return [];
      }
    },
    handleToggleExpand() {
      this.$emit("toggleExpand", WORKSPACE_TYPE.ALL_WORKSPACE);
    },
  },
  created() {
    this.fetchAllWorkspaces();
    eventbus.$on(EVENT.UPDATE_WORKSPACE_INFO, () => {
      this.fetchAllWorkspaces();
    });
  },
  beforeDestroy() {
    eventbus.$off(EVENT.UPDATE_WORKSPACE_INFO);
  },
};
</script>

<style lang="scss" scoped>
.NavAllWorkspaceMenu {
  &__noResult {
    color: var(--dGrey6-color);
    margin: 24px;
  }
}
</style>
