export const WORKSPACE_COLORS = {
  red: {
    color2: "E14447",
    direction: 180,
    color1: "FC585B",
    stop2: 100,
    stop1: 0,
  },
  purple: {
    direction: 180,
    stop2: 100,
    stop1: 0,
    color2: "4552AE",
    color1: "5966C2",
  },
  green: {
    direction: 180,
    color2: "00A56D",
    stop2: 100,
    stop1: 0,
    color1: "10B981",
  },
  blue: {
    stop2: 100,
    color2: "3678DD",
    color1: "4A8CF1",
    stop1: 0,
    direction: 180,
  },
  yellow: {
    stop1: 0,
    direction: 180,
    color2: "EBA400",
    stop2: 100,
    color1: "FFB800",
  },
};

export const getWorkspaceColorCss = (selectedColor) => {
  const { color1 } = WORKSPACE_COLORS[selectedColor] || WORKSPACE_COLORS.blue;
  return `#${color1}`;
};
