import {
  getUserLikesMMSOPs,
  getUserLikesWorkflows,
  setMMSOPLike,
  setWorkflowLike,
} from "@/server/user-likes-server.js";
import { loggingError } from "@/server/error-log-server";

import { SOP_TYPE } from "@/constants/sopType";

export const namespaced = true;
export const state = {
  userLikes: {
    [SOP_TYPE.WORKFLOW]: [], // array of workflow ids
    [SOP_TYPE.MMSOP]: [], // array of mmsop ids
  },
  emptyUserLikes: false,
  pendingOperations: new Set(), // prevent duplicated operations
};

export const getters = {
  getter_user_likes(state) {
    return state.userLikes;
  },
  getter_user_likes_workflows(state) {
    return state.userLikes[SOP_TYPE.WORKFLOW];
  },
  getter_user_likes_mmsops(state) {
    return state.userLikes[SOP_TYPE.MMSOP];
  },
};

export const mutations = {
  setUserLikes(state, { type, data, like }) {
    if (like === false) {
      const index = state.userLikes[type].indexOf(data[0]);
      state.userLikes[type].splice(index, 1);
    } else {
      //prevent duplicated
      state.userLikes[type] = [...new Set([...state.userLikes[type], ...data])];
    }
  },
  getUserLikesEnd(state) {
    console.log("loading userLikes from store ended");
  },
};

export const actions = {
  async fetchUserLikes({ commit, rootGetters }) {
    const userId = rootGetters["auth/getter_user_id"];
    if (!userId) {
      console.log("user not available for likes.");
      commit("getUserLikesEnd");
      return;
    }
    const isUserLikeFetched = Object.values(state.userLikes).some((likes) => likes.length > 0);
    if (state.emptyUserLikes || isUserLikeFetched) {
      commit("getUserLikesEnd");
      return;
    }

    try {
      const params = { userId };
      const [workflows, mmsops] = await Promise.all([getUserLikesWorkflows(params), getUserLikesMMSOPs(params)]);
      if (!workflows.ok || !mmsops.ok) throw new Error(workflows.errorMessage || mmsops.errorMessage);

      const userLikesWorkflowsIdList = workflows.data.items.map((workflow) => workflow.id);
      const userLikesMMSOPsIdList = mmsops.data.items.map((mmsop) => mmsop.id);

      if (userLikesWorkflowsIdList.length === 0 && userLikesMMSOPsIdList.length === 0) {
        state.emptyUserLikes = true;
      }
      commit("setUserLikes", { data: userLikesWorkflowsIdList, type: SOP_TYPE.WORKFLOW });
      commit("setUserLikes", { data: userLikesMMSOPsIdList, type: SOP_TYPE.MMSOP });
    } catch (error) {
      console.error({ error });
    } finally {
      commit("getUserLikesEnd");
    }
  },

  async updateWorkflowLike({ commit }, { id, like }) {
    if (state.pendingOperations.has(id)) return;
    try {
      state.pendingOperations.add(id);
      const params = { like, id };
      const { ok } = await setWorkflowLike(params);
      if (ok) {
        commit("setUserLikes", { type: SOP_TYPE.WORKFLOW, data: [id], like });
      }
    } catch (error) {
      console.error({ error });
      loggingError(error);
    } finally {
      state.pendingOperations.delete(id);
    }
  },

  async updateMMSOPLike({ commit }, { id, like }) {
    if (state.pendingOperations.has(id)) return;
    try {
      state.pendingOperations.add(id);
      const params = { like, id };
      const { ok } = await setMMSOPLike(params);
      if (ok) {
        commit("setUserLikes", { type: SOP_TYPE.MMSOP, data: [id], like });
      }
    } catch (error) {
      console.error({ error });
      loggingError(error);
    } finally {
      state.pendingOperations.delete(id);
    }
  },
};
