<template>
  <nav-collapse
    ref="myWorkspacesRef"
    class="NavMyWorkspaceMenu"
    :title="$t('sidemenu.myWorkspaces')"
    :isExpanded="isExpanded"
    @toggleExpand="handleToggleExpand"
  >
    <nav-workspace-item
      v-for="(workspace, idx) in fullWorkspaceList"
      :key="`${workspace.id}-${idx}`"
      :workspace="workspace"
      :workspaceGroup="workspace.workspaceGroupName"
      showWorkspaceGroupName
    />
    <div v-if="fullWorkspaceList.length === 0" class="NavMyWorkspaceMenu__noResult">
      {{ $t("sidemenu.noWorkspaces") }}
    </div>
  </nav-collapse>
</template>

<script>
import { mapGetters } from "vuex";

import eventbus from "@/main";
import { EVENT } from "@/constants/globalEvents";

import { loggingError } from "@/server/error-log-server";
import { getUserWorkspaceList } from "@/server/user-server";
import { WORKSPACE_TYPE } from "@/constants/sidemenu";

import NavCollapse from "./NavCollapse.vue";
import NavWorkspaceItem from "./NavWorkspaceItem.vue";

export default {
  name: "NavMyWorkspaceMenu",
  components: {
    NavCollapse,
    NavWorkspaceItem,
  },
  props: {
    isExpanded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fullWorkspaceList: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["getter_user_id"]),
  },
  methods: {
    async fetchMyWorkspaces() {
      try {
        const { ok, data, errorMessage } = await getUserWorkspaceList({ userId: this.getter_user_id });
        if (!ok) {
          console.error({ errorMessage });
          this.fullWorkspaceList = [];
          return;
        }
        this.fullWorkspaceList = data.items;
      } catch (error) {
        console.error({ error });
        loggingError(error);
      }
    },
    handleToggleExpand() {
      this.$emit("toggleExpand", WORKSPACE_TYPE.MY_WORKSPACE);
    },
  },
  created() {
    eventbus.$on(EVENT.UPDATE_WORKSPACE_INFO, () => {
      this.fetchMyWorkspaces();
    });
  },
  mounted() {
    this.fetchMyWorkspaces();
  },
  beforeDestroy() {
    eventbus.$off(EVENT.UPDATE_WORKSPACE_INFO);
  },
};
</script>

<style lang="scss" scoped>
.NavMyWorkspaceMenu {
  &__noResult {
    color: var(--dGrey6-color);
    margin: 24px;
  }
}
</style>
