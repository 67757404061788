import { FIREBASE_CONFIG } from "@/constants/env/firebase";
import { ROUTE_NAME } from "@/router/route";
export const FIREBASE_CONSTANTS = {
  STORAGE_BUCKET: FIREBASE_CONFIG.storageBucket,
};

export const FILE_TYPES = {
  VIDEO: "video",
  IMAGE: "image",
  DOCUMENT: "document",
  PDF: "pdf",
  VIDEO_CLIP: "videoClip",
  DIAGRAM: "diagram",
  STEP: "step",
  WORKFLOW: "workflow",
};

export const UPLOAD_PATHS = {
  SOP: {
    VIDEOS: "sop/videos",
    IMAGES: "sop/images",
    DOCUMENTS: "sop/documents",
    PDFS: "sop/pdfs",
    THUMBNAILS: "sop/videos/thumbnails",
  },
};

// SOP Operation Constants
export const SOP_OPERATIONS = {
  RENAME: "rename",
  DELETE: "delete",
  EDIT: "edit",
  DUPLICATE: "duplicate",
};

export const SOP_LOADING_STATES = {
  RENAME: "Updating SOP title...",
  DELETE: "Deleting SOP...",
  LOAD: "Loading SOPs...",
  DUPLICATE: "Duplicating SOP...",
};

export const SOP_ANALYTICS_EVENTS = {
  RENAME: {
    category: "EditorExplorer",
    action: "Edit SOP",
    name: "Rename SOP",
  },
  DUPLICATE: {
    category: "EditorExplorer",
    action: "Duplicate SOP",
    name: "Duplicate SOP",
  },
  DELETE: {
    category: "EditorExplorer",
    action: "Delete SOP",
    name: "Delete SOP",
  },
};

export const SOP_ERROR_MESSAGES = {
  RENAME_FAILED: "Failed to rename SOP. Please try again.",
  EMPTY_TITLE: "SOP title cannot be empty.",
  NETWORK_ERROR: "Network error occurred. Please check your connection and try again.",
  DUPLICATE_FAILED: "Failed to duplicate SOP. Please try again.",
  DELETE_FAILED: "Failed to delete SOP. Please try again.",
};

export const SOP_SUCCESS_MESSAGES = {
  DUPLICATE_SUCCESS: "SOP duplicated successfully!",
  RENAME_SUCCESS: "SOP renamed successfully!",
  DELETE_SUCCESS: "SOP deleted successfully!",
};

// Tab Options for SOP Creation
export const SOP_TAB_OPTIONS = {
  MANUAL: "manual",
  FROM_PDF: "fromPDF",
  FROM_VIDEO: "fromVideo",
};

// Tab Labels (if needed)
export const SOP_TAB_LABELS = {
  [SOP_TAB_OPTIONS.MANUAL]: "Manual",
  [SOP_TAB_OPTIONS.FROM_PDF]: "From PDF",
  [SOP_TAB_OPTIONS.FROM_VIDEO]: "From Video",
};

// Tab Indices (if needed)
export const SOP_TAB_INDICES = {
  [SOP_TAB_OPTIONS.MANUAL]: 0,
  [SOP_TAB_OPTIONS.FROM_PDF]: 1,
  [SOP_TAB_OPTIONS.FROM_VIDEO]: 2,
};

// Step Types for SOP
export const SOP_STEP_TYPES = {
  INTRODUCTION: "introduction",
  CONCLUSION: "conclusion",
  STEP: "step",
  QUIZ: "quiz",
  WORKFLOW: "workflow",
};

// Special Step Types that need special handling
export const SPECIAL_STEP_TYPES = [SOP_STEP_TYPES.INTRODUCTION, SOP_STEP_TYPES.CONCLUSION];

// Add new constant for steps that need special processing
export const SPECIAL_PROCESSING_STEPS = {
  QUIZ: SOP_STEP_TYPES.QUIZ,
};

// Make sure these exports exist
export const QUIZ_TYPES = {
  SINGLE_SELECTION: "single",
  TRUE_FALSE: "trueFalse",
};

export const QUIZ_ANSWER_FORMATS = {
  [QUIZ_TYPES.TRUE_FALSE]: ["True", "False"],
  [QUIZ_TYPES.SINGLE_SELECTION]: ["", "", "", ""],
};

export const CREATE_SOP_TAB_CONFIG = [
  {
    key: SOP_TAB_OPTIONS.MANUAL,
    label: "skills.assessmentImportManuallyTitle",
    icon: ["fal", "paintbrush-pencil"],
  },
  {
    key: SOP_TAB_OPTIONS.FROM_VIDEO,
    label: "sop.fromVideo",
    icon: ["far", "video"],
  },
  {
    key: SOP_TAB_OPTIONS.FROM_PDF,
    label: "sop.fromPDF",
    icon: ["fas", "file-invoice"],
  },
];

export const CREATE_SOP_FILE_CONSTANTS = {
  PDF: {
    MAX_SIZE: 50 * 1024 * 1024, // 50MB
    ALLOWED_TYPES: ["application/pdf"],
  },
};

export const CREATE_SOP_FORM_RULES = {
  required: (value) => !!value || "rules.required",
};

export const CREATE_SOP_DEFAULT_TASK_METADATA = {
  version: "1.0",
  status: "draft",
  stepsCount: 0,
  duration: 30,
};

/**
 * Standardized content types used across DTaskManager components
 * These types match the backend API expectations
 */
export const CONTENT_TYPES = {
  IMAGE: "image",
  DIAGRAM: "diagram", // replaces 'image'
  PDF: "pdf", // replaces 'document'
  STEP: "step",
  WORKFLOW: "workflow",
  VIDEO_CLIP: "videoClip", // replaces 'video'
};

/**
 * Maps frontend content types to standardized backend types
 */
export const CONTENT_TYPE_MAPPING = {
  image: CONTENT_TYPES.IMAGE,
  diagram: CONTENT_TYPES.DIAGRAM,
  document: CONTENT_TYPES.PDF,
  pdf: CONTENT_TYPES.PDF,
  step: CONTENT_TYPES.STEP,
  workflow: CONTENT_TYPES.WORKFLOW,
  video: CONTENT_TYPES.VIDEO_CLIP,
  videoClip: CONTENT_TYPES.VIDEO_CLIP,
};

/**
 * Get standardized content type
 * @param {string} type - Frontend content type
 * @returns {string} Standardized backend content type
 */
export const getStandardizedContentType = (type) => {
  return CONTENT_TYPE_MAPPING[type] || type;
};

/**
 * Accepted file types for each content type
 */
export const ACCEPTED_FILE_TYPES = {
  [CONTENT_TYPES.DIAGRAM]: ["image/jpeg", "image/png", "image/gif"],
  [CONTENT_TYPES.PDF]: ["application/pdf"],
  [CONTENT_TYPES.VIDEO_CLIP]: ["video/mp4", "video/webm"],
};

/**
 * Navigates back in history if available, otherwise navigates to a fallback route
 * @param {Object} router - Vue Router instance
 * @param {Object} options - Navigation options
 * @param {string} [options.fallbackPath=ROUTE_PATH.OWNED_BY_ME_MM_SOP] - Fallback route path if no history
 * @param {boolean} [options.useName=false] - Whether to use route name instead of path for fallback
 * @returns {Promise<void>}
 */
export const navigateBackOrFallback = async (router, options = {}) => {
  const { fallbackPath = ROUTE_NAME.OWNED_BY_ME_MM_SOP, useName = false } = options;

  try {
    if (window.history.length > 1) {
      await router.go(-1);
    } else {
      // If no history, navigate to fallback route
      if (useName) {
        await router.push({ name: fallbackPath });
      } else {
        await router.push(fallbackPath);
      }
    }
  } catch (error) {
    console.error("Navigation error:", error);
    // If error occurs, still try to navigate to fallback
    if (useName) {
      await router.push({ name: fallbackPath });
    } else {
      await router.push(fallbackPath);
    }
  }
};
