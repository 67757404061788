import axios from "axios";
import authChoice from "../../auth";
import { apiWrapperGenerator } from "./lib";
import services from "@/constants/env/services";

export const adminServer = axios.create({
  baseURL: services.admin,
});

adminServer.interceptors.request.use(
  async (config) => {
    try {
      config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    } finally {
      return config;
    }
  },
  (error) => Promise.resolve(error)
);

const apiWrapper = apiWrapperGenerator(adminServer);

export const checkIpAddress = () => apiWrapper("post", "/v1/permission/check-ip-access");

export const validateRusticiLogin = (rusticiToken, params) =>
  apiWrapper("post", `/v1/rustici/validate-user`, params, {
    headers: {
      "Content-Type": "application/json",
      "x-rustici-token": rusticiToken,
    },
  });

export const fetchWorkspaceByWorkspaceId = async (workspaceId = "") => apiWrapper("get", `/v1/groups/${workspaceId}`);

export const fetchOrganizationLogo = (organizationId) =>
  apiWrapper("get", `/v1/organization-settings/${organizationId}/logo`);

/**
 * Generates a JWT for Zendesk login with the user attributes and redirects to Zendesk
 * @param {Object} params - Parameters for the API call
 * @param {string} params.return_to - Zendesk URL where the user should be redirected after SSO login
 * @returns {Promise} - The API response with redirectUrl in the item property
 */
export const zendeskSsoLogin = (params) => {
  return apiWrapper("post", "/v1/zendesk-sso/login", {
    return_to: params.return_to,
  });
};

export default apiWrapper;
